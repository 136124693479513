import { isPlatformBrowser, Location } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '../../../express.tokens';
import { Request } from 'express';

import { DEFAULT_CURRENCY_CODE } from '../../common/constants';
import { GetProductDetailQuery, ProductPreviewFragment } from '../common/gql/graphql';

export type JsonLdType =
    | 'Product'
    | 'Review'
    | 'Rating'
    | 'Person'
    | 'AggregateRating'
    | 'Brand'
    | 'AggregateOffer'
    | 'Offer'
    | 'Organization'
    | 'SearchAction'
    | 'WebSite';

export interface JsonLd {
    '@type': JsonLdType;

    [key: string]: string | JsonLd | Array<string | JsonLd>;
}

@Injectable({
    providedIn: 'root',
})
export class JsonLdService {
    constructor(
        private location: Location,
        @Optional() @Inject(REQUEST) private req: Request,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {}

    getProductData(
        product: NonNullable<GetProductDetailQuery['product']>,
        preview: ProductPreviewFragment,
    ): JsonLd {
        const jsonLd: JsonLd = {
            '@type': 'Product',
            name: preview.name,
            image: product.featuredAsset?.preview ?? '',
            description: this.strip(preview.description),
            sku: product.variants[0]?.sku,
            url: this.getBaseUrl() + this.getPath(),
        };
        const gtin = product.variants[0]?.customFields?.gtin;
        if (gtin?.length === 8) {
            jsonLd['gtin8'] = gtin;
        }
        if (gtin?.length === 12) {
            jsonLd['gtin12'] = gtin;
        }
        if (gtin?.length === 13) {
            jsonLd['gtin13'] = gtin;
        }
        if (gtin?.length === 14) {
            jsonLd['gtin14'] = gtin;
        }
        const availability = product.variants.every((v) => v.stockLevel === 'OUT_OF_STOCK')
            ? 'https://schema.org/OutOfStock'
            : 'https://schema.org/InStock';

        const brandFacet = product.facetValues.find((v) => v.facet.code === 'brand');
        if (brandFacet) {
            jsonLd['brand'] = {
                '@type': 'Brand',
                name: brandFacet.name,
            };
        }

        if (1 < product.variants.length) {
            const allPrices = product.variants.map((v) => v.priceWithTax);

            jsonLd['offers'] = {
                '@type': 'AggregateOffer',
                itemCondition: 'https://schema.org/NewCondition',
                lowPrice: (Math.min(...allPrices) / 100).toString(),
                highPrice: (Math.max(...allPrices) / 100).toString(),
                priceCurrency: DEFAULT_CURRENCY_CODE,
                offerCount: product.variants.length.toString(),
                availability,
                url: jsonLd['url'],
            };
        } else {
            jsonLd['offers'] = {
                '@type': 'Offer',
                priceCurrency: DEFAULT_CURRENCY_CODE,
                itemCondition: 'https://schema.org/NewCondition',
                price: (product.variants[0]?.priceWithTax / 100).toString(),
                availability,
                url: jsonLd['url'],
            };
        }

        if (0 < product.reviews.totalItems) {
            const firstReview = product.reviews.items[0];
            jsonLd['review'] = {
                '@type': 'Review',
                datePublished: firstReview.createdAt,
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: firstReview.rating.toString(),
                    bestRating: '5',
                },
                author: {
                    '@type': 'Person',
                    name: firstReview.authorName,
                },
                reviewBody: firstReview.body ?? '',
            };
            jsonLd['aggregateRating'] = {
                '@type': 'AggregateRating',
                ratingValue: preview.customFields?.reviewRating?.toString() ?? '',
                ratingCount: product.reviews.totalItems.toString(),
                bestRating: '5',
                worstRating: '0',
            };
        }

        return jsonLd;
    }

    getOrgData(): JsonLd {
        return {
            '@type': 'Organization',
            name: 'Bromleys Art Supplies',
            url: this.getBaseUrl(),
            logo: this.getBaseUrl() + '/assets/images/header-logo-new.webp',
            sameAs: [
                'https://www.facebook.com/BromleysArt',
                'https://twitter.com/BromleysArt',
                'https://www.youtube.com/@BromleysArtSupplies',
                'https://www.instagram.com/bromleysart/',
                'https://www.pinterest.co.uk/BromleysArt/',
                'https://uk.linkedin.com/company/ken-bromley-art-supplies-ltd',
                'https://www.flickr.com/people/kenbromleyartsupplies/',
            ],
        };
    }

    getSearchData(): JsonLd {
        const baseUrl = this.getBaseUrl();
        return {
            '@type': 'WebSite',
            name: 'Art Supplies',
            url: baseUrl,
            potentialAction: {
                '@type': 'SearchAction',
                target: `${baseUrl}/search?term={search_term_string}`,
                'query-input': 'required name=search_term_string',
            },
        };
    }

    private getPath(): string {
        if (isPlatformBrowser(this.platformId)) {
            return window.location.pathname;
        } else {
            return this.req?.path;
        }
    }

    private getBaseUrl(): string {
        if (isPlatformBrowser(this.platformId)) {
            return window.location.origin.replace(/\/$/, '');
        } else {
            return process.env['STOREFRONT_URL']?.replace(/\/$/, '') ?? '';
        }
    }

    private strip(html: string) {
        return html.replace(/<\/?[^>]+>/gi, '');
    }
}
